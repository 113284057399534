import React, {useEffect, useState} from 'react';
import './App.css';
import {Card, Input, message} from 'antd'
import {filter} from "lodash-es";
import CopyToClipboard from "react-copy-to-clipboard";

function App() {
    const [projects, setProjects] = useState<any[]>([]);
    const [filterProjects, setFilterProjects] = useState<any[]>([]);
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    useEffect(() => {
        async function fetchProjects() {
            let resp = await fetch("/api/projects.json");
            let data = await resp.json();
            setProjects(data);
        }

        fetchProjects().then();
    }, [])

    useEffect(() => {
        if (searchKeyword.trim() === '') {
            setFilterProjects(projects);
            return;
        }

        let result = filter(projects, it => {
            let keyword = searchKeyword.toLowerCase().trim();

            return it.url.toLowerCase().includes(keyword) || it.name.toLowerCase().includes(keyword) || it.owner?.includes(keyword);
        })

        setFilterProjects(result);
    }, [searchKeyword, projects]);

    function handleCopy() {
        message.success("已复制到剪贴板").then()
    }

    function handleCopyClick(e: React.MouseEvent) {
        e.preventDefault();
    }

    return (
        <div className="App">
            <div className="search">
                <Input.Search placeholder="请输入关键字搜索" onSearch={value => setSearchKeyword(value)} allowClear={true}/>
            </div>
            <div className="content">
                {
                    filterProjects.map((it, index) =>
                        <a href={it.url} target="_blank" rel="noreferrer" key={index}>
                            <Card className="card" title={it.name} hoverable={true} size="small">
                                <div className="url tag-item">
                                    <span className="name">地址：</span>
                                    <span className="value">{it.url}</span>
                                    <CopyToClipboard text={it.url} onCopy={handleCopy}>
                                        <span className="copy" onClick={handleCopyClick}>复制</span>
                                    </CopyToClipboard>
                                </div>
                                <div className="owner tag-item">
                                    <span className="name">管理者：</span>
                                    <span className="value">{it.owner}</span>
                                </div>
                                <div className="version tag-item">
                                    <span className="name">版本：</span>
                                    <span className="value">{it.version}</span>
                                </div>
                            </Card>
                        </a>
                    )
                }
            </div>
        </div>
    );
}

export default App;
